<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    controller: ReturnType<typeof useModal>;
    side: "left" | "right";
    class?: string;
    layerClass?: string;
  }>(),
  {
    side: "right",
    class: "",
    layerClass: "",
  },
);

const { controller, side } = toRefs(props);
const { isOpen, close } = controller.value;

const modalBackground = useModalBackground();
watch(isOpen, modalBackground.toggle);

const { escape } = useMagicKeys();
if (escape) watch(escape, close);

const sidebarContentElement = ref();
onClickOutside(sidebarContentElement, close, {
  ignore: ["header", ".top-bar", "[role=dialog]", ".login-modal"],
});

const headerOffset = inject<number>("headerOffset", 0);
</script>

<template>
  <ClientOnly>
    <Teleport to="body">
      <Transition
        enter-active-class="transition ease-out duration-500 transform"
        :enter-from-class="
          side === 'right' ? 'translate-x-full' : '-translate-x-full'
        "
        :enter-to-class="side === 'right' ? 'translate-x-0' : '-translate-x-0'"
        leave-active-class="transition ease-in duration-300 transform"
        :leave-from-class="
          side === 'right' ? 'translate-x-0' : '-translate-x-0'
        "
        :leave-to-class="
          side === 'right' ? 'translate-x-full' : '-translate-x-full'
        "
      >
        <div
          v-if="isOpen"
          class="pointer-events-none fixed inset-y-0 flex max-w-full max-xl:z-3000"
          :data-testid="`sidebar-${side}`"
          :class="side === 'right' ? 'right-0' : 'left-0'"
        >
          <div
            ref="sidebarContentElement"
            class="pointer-events-auto w-screen max-w-md xl:max-w-lg"
          >
            <div
              class="flex h-full flex-col bg-white shadow-xl"
              :class="props.class"
              :style="{ marginTop: `${headerOffset}px` }"
            >
              <slot></slot>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </ClientOnly>
</template>
